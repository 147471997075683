import React, { useState } from "react"
import Editor from "./editor"
import Renderer from "./renderer"

const EditorWithPreview = () => {
  const [input, setInput] = useState([])
  return (
    <section className="grid h-full w-full grow grid-cols-2">
      <Editor setInputData={setInput} />
      <Renderer input={input} />
    </section>
  )
}

export default EditorWithPreview
