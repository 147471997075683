import React, { useEffect, useState } from "react"
import CodeEditor from "react-simple-code-editor"
import Prism from "prismjs/components/prism-core"
import "prismjs/components/prism-clike"
import "prismjs/components/prism-json" // need this
import "./prism.css"

const getJson = value => {
  if (typeof value === "object") return JSON.stringify(value, null, 2)
  return value
}

const template = [
  {
    type: "header",
    client: "Test client",
    date: "Oct 24, 2022",
    projectName: "Smart contract development",
  },
  {
    type: "section",
    title: "Service details",
    children: [
      {
        type: "card",
        title: "Stage 1: Smart contract development",
        footer: {
          type: "timeInvestmentFooter",
          time: "1 month",
          investment: "5000 BUSD",
        },
        children: [
          {
            type: "paragraph",
            content: "We will develop an amazing smart contract for you",
          },
          {
            type: "unorderedList",
            title: "The contract will meet the following criteria:",
            children: [
              { type: "text", content: "Gas-optimized functions" },
              { type: "text", content: "Audit-ready code" },
            ],
          },
        ],
      },
    ],
  },
  {
    type: "section",
    title: "Payment",
    children: [
      {
        type: "unorderedList",
        title: "All deposits will be via binance, following this schedule:",
        children: [
          { type: "text", content: "30% before starting the project" },
          { type: "text", content: "70% when finalizing" },
        ],
      },
    ],
  },
]

const Editor = ({ setInputData }) => {
  const [validInput, setValidInput] = useState([])
  const [input, setInput] = useState([])
  useEffect(() => {
    const storageQuotation = localStorage.getItem("quotationEditor")
    if (!storageQuotation) return setInput(template)
    const quotation = JSON.parse(storageQuotation)
    setInput(quotation)
  }, [])

  useEffect(() => {
    if (typeof input === "object") setValidInput(input)
  }, [input])

  useEffect(() => {
    setInputData(validInput)
    localStorage.setItem("quotationEditor", JSON.stringify(validInput))
  }, [validInput])

  return (
    <section className="h-full w-full max-w-4xl grow overflow-scroll bg-slate-900 p-4 font-mono">
      <CodeEditor
        value={getJson(input)}
        highlight={code => Prism.highlight(code, Prism.languages.json)}
        tabSize={2}
        onValueChange={value => {
          try {
            setInput(JSON.parse(value))
          } catch (error) {
            setInput(value)
          }
        }}
        className="editor h-full"
        textareaClassName="caret-white outline-none"
        preClassName="language-json z-50"
      />
    </section>
  )
}

export default Editor
