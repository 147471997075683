import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/common/body/layout"
import { HeadHelmet } from "../../components/common/body/seo"
import EditorWithPreview from "../../components/quotation/editor"

export default function Quotation({ data: { content } }) {
  return (
    <Layout
      lang={content.node_locale}
      site={content.title}
      solutions={content.solutionList}
      contactLink={content.contactLink}
      strings={content.strings}
      solutionsHeader={content.solutionsHeader}
      fixed
    >
      <EditorWithPreview />
    </Layout>
  )
}

export const Head = ({ location, data: { content } }) => {
  return (
    <HeadHelmet
      seo={{
        description: content.description,
      }}
      lang={content.node_locale}
      title="Tsubaki Labs - Quotation Editor"
      site={content.title}
      location={location.pathname}
      preview={content.twitterImage.localFile.publicURL}
      type="webpage"
    />
  )
}

export const query = graphql`
  {
    content: contentfulPage {
      title
      description
      solutionsHeader
      solutionsTitle
      solutionList {
        name
        excerpt
        slug
        released
        available
        shown
        node_locale
      }
      contactInformation {
        dummy
        title
        description
        actionButtonLabel
      }
      contactLink {
        label
        link
      }
      strings {
        goHome
        copyright
        openMenu
        about {
          link
          label
        }
      }
      twitterImage {
        localFile {
          publicURL
        }
      }
      node_locale
    }
  }
`
